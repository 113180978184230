var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"patient-data-content"}},[_c('div',{staticClass:"patient-data"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('p',{staticClass:"patient-title m-0"},[_vm._v(" Paciente "),(_vm.patient)?_c('span',{staticClass:"patient-profile",on:{"click":_vm.goPatientProfile}}):_vm._e()])]),_c('div',{staticClass:"patient-name"},[_vm._v(" "+_vm._s(_vm.patient.name)+" "),_c('Export',{staticClass:"patient-profile",on:{"click":_vm.goPatient}})],1),(_vm.patient)?_c('div',{staticClass:"inline-flex"},[(_vm.patient.birthday)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"birthday-wrapper"},[_c('div',{staticClass:"age"},[_vm._v("Nascimento:")]),(
              _vm.patient !== null &&
              _vm.patient.birthday &&
              _vm.moment(_vm.patient.birthday).isValid()
            )?_c('div',{staticClass:"birthday"},[_vm._v(" "+_vm._s(_vm.moment(_vm.patient.birthday).format('DD/MM/YYYY'))+" ")]):_vm._e(),(_vm.patient.birthday)?_c('div',{staticClass:"years"},[(_vm.moment().diff(_vm.moment(_vm.patient.birthday), 'days') <= 30)?_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.moment().diff(_vm.moment(_vm.patient.birthday), 'days'))+" dias ")]):(
                _vm.moment().diff(_vm.moment(_vm.patient.birthday), 'month') > 1 &&
                _vm.moment().diff(_vm.moment(_vm.patient.birthday), 'month') < 12
              )?_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.moment().diff(_vm.moment(_vm.patient.birthday), 'month'))+" meses ")]):(!_vm.patient.birthday)?_c('p',{staticClass:"value"},[_vm._v("-")]):_c('p',{staticClass:"value"},[_vm._v(" ("+_vm._s(_vm.moment().diff(_vm.moment(_vm.patient.birthday), 'years'))+" anos) ")])]):_vm._e()])]):_vm._e(),(_vm.patient.cellphone)?_c('div',{staticClass:"flex"},[_c('div',[_vm._v("Celular:")]),(_vm.patient !== null && _vm.patient.cellphone)?_c('a',{staticClass:"whatsapp-link",on:{"click":function($event){return _vm.openWhatsApp(_vm.patient.cellphone)}}},[_c('span',[_c('p',[_vm._v(_vm._s(_vm.patient.cellphone))])]),_c('WhatsAppIcon')],1):_vm._e()]):_vm._e(),(_vm.patient?.medical_record_number)?_c('div',{staticClass:"flex"},[_c('div',[_vm._v("Prontuário:")]),(_vm.patient.medical_record_number != null)?_c('a',{staticClass:"medical-record-container",on:{"click":_vm.goPatient}},[_c('span',[_vm._v(" "+_vm._s(_vm.patient?.medical_record_number)+" ")]),_c('Export')],1):_vm._e()]):_vm._e()]):_vm._e(),(_vm.patient && (!_vm.patient.address_id || !_vm.patient.name))?_c('div',[_c('span',{staticClass:"patient-alert"},[_vm._v(" Paciente com cadastro incompleto, finalize o cadastro deste paciente "),_c('router-link',{attrs:{"to":`/pacientes/${_vm.patient.id}/edit`}},[_vm._v(" Clicando aqui. ")])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }