<template>
  <div id="patient-data-content">
    <div class="patient-data">
      <div class="d-flex justify-content-between align-items-center">
        <p class="patient-title m-0">
          Paciente
          <span
            v-if="patient"
            @click="goPatientProfile"
            class="patient-profile"
          >
          </span>
        </p>
      </div>
      <div class="patient-name">
        {{ patient.name }}
        <!-- <img
        v-if="checkGlauco(appointmentForm.patient.id)"
        src="@/assets/images/glauco-symbol-plus.png" class="logo-glauco"> -->
        <Export @click="goPatient" class="patient-profile" />
      </div>

      <div v-if="patient" class="inline-flex">
        <div v-if="patient.birthday" class="flex">
          <div class="birthday-wrapper">
            <div class="age">Nascimento:</div>
            <div
              class="birthday"
              v-if="
                patient !== null &&
                patient.birthday &&
                moment(patient.birthday).isValid()
              "
            >
              {{ moment(patient.birthday).format('DD/MM/YYYY') }}
            </div>
            <div class="years" v-if="patient.birthday">
              <p
                class="value"
                v-if="moment().diff(moment(patient.birthday), 'days') <= 30"
              >
                {{ moment().diff(moment(patient.birthday), 'days') }} dias
              </p>
              <p
                class="value"
                v-else-if="
                  moment().diff(moment(patient.birthday), 'month') > 1 &&
                  moment().diff(moment(patient.birthday), 'month') < 12
                "
              >
                {{ moment().diff(moment(patient.birthday), 'month') }} meses
              </p>
              <p class="value" v-else-if="!patient.birthday">-</p>
              <p class="value" v-else>
                ({{ moment().diff(moment(patient.birthday), 'years') }} anos)
              </p>
            </div>
          </div>
        </div>

        <div v-if="patient.cellphone" class="flex">
          <div>Celular:</div>
          <a
            class="whatsapp-link"
            @click="openWhatsApp(patient.cellphone)"
            v-if="patient !== null && patient.cellphone"
          >
            <span>
              <p>{{ patient.cellphone }}</p>
            </span>
            <WhatsAppIcon />
          </a>
        </div>

        <div v-if="patient?.medical_record_number" class="flex">
          <div>Prontuário:</div>
          <a
            class="medical-record-container"
            @click="goPatient"
            v-if="patient.medical_record_number != null"
          >
            <span>
              {{ patient?.medical_record_number }}
            </span>
            <Export />
          </a>
        </div>
      </div>

      <div v-if="patient && (!patient.address_id || !patient.name)">
        <span class="patient-alert">
          Paciente com cadastro incompleto, finalize o cadastro deste paciente
          <router-link :to="`/pacientes/${patient.id}/edit`">
            Clicando aqui.
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import WhatsAppIcon from '@/assets/icons/whatsapp-icon.svg'
import Export from '@/assets/icons/export.svg'

export default {
  props: {
    patient: Object
  },
  components: {
    WhatsAppIcon,
    Export
  },

  data() {
    return {
      loading: false
    }
  },
  methods: {
    goPatientProfile() {
      const route = this.$router.resolve(`/pacientes/${this.patient.id}`)
      window.open(route.href, '_blank')
    },
    goPatient() {
      const route = this.$router.resolve(
        `/pacientes/${this.patient.id}`
      )
      window.open(route.href, '_blank')
    },
    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, '')
      if (whatsappNumber.length < 10) {
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
      window.open(
        `https://api.whatsapp.com/send?phone=55${whatsappNumber}`,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss">
#patient-data-content {
  width: 100%;
  .patient-data {
    p {
      margin-bottom: 0;
    }

    .patient-title {
      display: flex;
      color: #304388;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .divider {
      width: 100%;
      height: 0.5px;
      background-color: var(--neutral-200);
      margin: 10px 0;
    }

    .patient-name {
      margin-bottom: 8px;
      margin-top: 8px;
      font-family: Nunito Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      color: #304388;
      padding-left: 0px;
    }
  }

  .birthday-wrapper {
    display: flex;
    border-radius: 5px;
    align-items: center;
    flex-direction: row;
    padding: 0px 4px;

    &.active {
      border: 1px solid var(--orange);
      margin-left: 5px;
    }
  }

  .patient-name {
    color: var(--type-active);
    font-size: 16px;
  }

  .patient-profile > span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: var(--blue-500);

    &:hover {
      text-decoration: underline;
    }
  }

  .inline-flex {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
    margin-bottom: 1rem;
  }

  .flex {
    display: flex;
    align-items: center !important;
    gap: 5px;
  }

  .birthday {
    color: var(--type-active);
    font-weight: 700;
  }

  .years {
    color: var(--type-placeholder);
  }

  .whatsapp-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    text-decoration: underline;
    cursor: pointer;

    span {
      font-weight: 700;

      p {
        color: var(--blue-500);
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        text-decoration: none;
      }
    }

    svg {
      height: 28px;
      width: 28px;
    }
  }

  .medical-record-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--blue-500);
      font-weight: 700;
      cursor: pointer;
    }
  }
  .patient-profile {
    cursor: pointer;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
</style>
